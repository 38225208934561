import './App.css';
import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { FaCheck, FaSpinner } from 'react-icons/fa';
import { AccountId, LedgerId} from '@hashgraph/sdk';
import {DAppConnector, HederaSessionEvent, HederaChainId, HederaJsonRpcMethod} from "@hashgraph/hedera-wallet-connect";
import {Frame} from './Frame/index.js';

function App() {
  const hashconnectRef = useRef(null);
  const hWalletConnectRef = useRef(null);
  const verificationCode = useRef('');
  const discordIdCode = useRef('');
  const [discordError, setDiscordError] = useState(false);
  const [isPaired, setPaired] = useState(false);
  const [isVerified, setVerified] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    verificationCode.current = urlParams.get('code');
    discordIdCode.current = urlParams.get('state');
    setDiscordError(urlParams.get('error') != null || urlParams.get('code') === null || urlParams.get('state') === null);

    const initHashconnect = async () => {
      //create the hashconnect instance
      const appMetadata = {
        name: "WhataChecker",
        description: "Your all-in-one verification tool for managing your hedera community",
        icons: ["https://white-implicit-sparrow-591.mypinata.cloud/ipfs/QmX5FAho3cSa8fwSpagneRDy4BxL8BbpeZ7JigGUHkaxXk?pinataGatewayToken=2e1Cp6Cgu2LHBClPXRG8aOMp0xaCe3gtoi1Hb_X5ZidBD4gFOWapWYu24lOiuDKe"],
        url: "https://verify.v2.whatalab.io"
    }
      const dAppConnector = new DAppConnector(
        appMetadata,
        LedgerId.MAINNET,
        'ce908f2d9e2ee7fcd7076c1b0cd8032d',
        Object.values([HederaJsonRpcMethod.SignMessage]),
        [HederaSessionEvent.ChainChanged, HederaSessionEvent.AccountsChanged],
        [HederaChainId.Mainnet]
      )

      hashconnectRef.current = dAppConnector;
      //initialize
      await hashconnectRef.current.init();
      console.log(dAppConnector?.extensions);
        const signer = dAppConnector.getSigner('hedera:mainnet:0.0.3929752');
        signer.signMessage()
  }

  initHashconnect().then(() => {
    console.log('Hashconnect initialized');
    }).catch((error) => {
    console.error('Error initializing hashconnect', error)
  });
  }, []);

  const disconnect = async () => {
    await hashconnectRef.current.disconnectAll();
    setPaired(false);
  };

  const handleConnectClick = async () => {
    if (isPaired && !isVerified) {
      await disconnect();
      window.location.reload();
      return;
    } else if (isPaired && isVerified) {
      return;
    }
    setShowModal(true);
  };

  const extractAccountIdFromMetadata = (metadata) => {
    //extract the account id from the metadata "hedera:mainnet:0.0.3929752" => 0.0.3929752 // Possibly do a check on the network and stuff
    return metadata.split(':')[2];
  }

  const handleSignClick = async () => {
    if (isVerified) {
      await disconnect();
      window.location.href = 'https://discord.com/oauth2/authorize?client_id=1105931619476443226&response_type=code&redirect_uri=https%3A%2F%2Fverify.v2.whatalab.io&scope=identify+email+connections+guilds&state=' + discordIdCode.current;
      return;
    }
    setLoading(true);
    const message = "With the new integrations, which we make available to creators, new fields of gamification and attraction are opened on the held, both NFTs and Hedera's native HTS tokens.!";
    const params = {
      signerAccountId: 'hedera:mainnet:'+ hWalletConnectRef.current,
      message: message
    }
    const signature = await hashconnectRef.current.signMessage(params).catch((error) => {
      console.error("Error signing message", error);
    });

    console.log("Signature", signature);
    if (signature) {
      try { //https://whatalabapi-dev.up.railway.app/
        const response = await axios.post(
          `https://whatalabapi-dev.up.railway.app/api/v2/dashboard/auth/verify-wallet/${hWalletConnectRef.current}/${verificationCode.current}/${discordIdCode.current}`,
          {
            headers: {
              'Access-Control-Allow-Origin': '*',
            },
          }
        );
        console.log(response);
        if (response.status === 200) {
          setVerified(true);
        } else {
          setVerified(false);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      setVerified(false);
    }
    setLoading(false);
  };

  const handleModalOpen = () => {
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  const openHashpackWallet = async () => {
    setShowModal(false);
    const extension_id = "gjagmgiddbbciopjhllkdnddhcglnemk"
    const session = await hashconnectRef.current.connectExtension(extension_id);
    if(session.namespaces.hedera.accounts.length > 0) {
      hWalletConnectRef.current = extractAccountIdFromMetadata(session.namespaces.hedera.accounts[0]);
      setPaired(true);
    }
    console.log('session:', session);
  }

  const openKabilaWallet = async () => {
    setShowModal(false);
    const extension_id = "cnoepnljjcacmnjnopbhjelpmfokpijm"
    const session = await hashconnectRef.current.connectExtension(extension_id);
    if(session.namespaces.hedera.accounts.length > 0) {
      hWalletConnectRef.current = extractAccountIdFromMetadata(session.namespaces.hedera.accounts[0]);
      setPaired(true);
    }
    console.log('session:', session);
  }

  const openWalletConnectModal = async () => {
    setShowModal(false);
    const session = await hashconnectRef.current.openModal();
    if(session.namespaces.hedera.accounts.length > 0) {
      hWalletConnectRef.current = extractAccountIdFromMetadata(session.namespaces.hedera.accounts[0]);
      setPaired(true);
    }
    console.log('session:', session);
  };

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(window.location.href);
  };

  if (discordError) {
    return (
      <div className="app">
        <div className="body">
        <img src={require('./whatapanel_header.png')} alt="WhataTools Logo" className="header-logo" />
          <div className="card">
            <div>
              <h4 className="error-message">Error while authenticating with discord. Please, try again</h4>
            </div>
          </div>
          <img src={require('./whatachecker_logo.webp')} alt="WhataTools Logo" className="footer-logo" />
        </div>
      </div>
    );
  }

  return (
    <div className="app">
      <div className="body">
        <img src={require('./whatapanel_header.png')} alt="WhataTools Logo" className="header-logo" />
        { showModal ? (
                  <Frame close={handleModalClose} openHashpack={openHashpackWallet} openKabila={openKabilaWallet} openWalletConnect={openWalletConnectModal} className='connect-wallet-modal'></Frame>
        ) : null
        }
        <div className="card">
          <div className="card-content">
            <button onClick={handleConnectClick} className={isPaired ? 'connected-button' : 'connect-button'}>
              <p className='connect-text'>{isPaired ? hWalletConnectRef.current : 'Connect Wallet'}</p>
              <p className='disconnect-text'>Disconnect Wallet</p>
            </button>
                <button onClick={async () => {await handleSignClick()}} className={isVerified ? 'sign-complete' : 'sign-button'}>
                  { isLoading ? (
                    <FaSpinner className="spinner" />
                  ) : isVerified ? (
                    <FaCheck className="check-icon" />,
                    <p className='connect-text'>Another Wallet?</p>
                  ) : (
                    <p className='connect-text'>Verify Wallet</p>
                  )}
                </button>
          </div>
        </div>
        <img src={require('./whatachecker_logo.webp')} alt="WhataTools Logo" className="footer-logo" />
      </div>
    </div>
  );
}

export default App;
/*  */
