import React from "react";
import { IconCloseSmall1 } from "../icons/IconCloseSmall1";
import "./style.css";


export const Frame = ({close, openKabila, openHashpack, openWalletConnect}) => {

  return (
    <div className="frame">
      <div className="list-wallet">
        <img className="img option" alt="List title icon" src={require("../hashpack-button.png")} onClick={openHashpack} />
        <img className="list-title-icon-2 option" alt="List title icon" src={require("../kabila-button.png")} onClick={openKabila} />
        <img className="list-title-icon-3 option" alt="List title icon" src={require("../wallet-connect-button.png")} onClick={openWalletConnect} />
      </div>
      <div className="nav">
        <div className="connect-your-wallet">CONNECT YOUR WALLET</div>
        <div className="close" onClick={close}>
        <IconCloseSmall1 className="icon-close-small" />
        </div>
      </div>
    </div>
  );
};
